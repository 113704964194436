// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Layout
@import '_layout';

// Bootstrap
@import '~bootstrap/scss/bootstrap';



/*
|--------------------------------------------------------------------------
| Expired Page
|--------------------------------------------------------------------------
*/

@media screen and (min-resolution: 120dpi) {
body {zoom: 0.8;}
}

.expired-text {
  margin-top: 100px;

  font-size: 1.2rem;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.card-text.price {
  font-size: 0.8rem !important;
}

@media print {
  body * {
    visibility: hidden;
  }
  #section-to-print, #section-to-print * {
    visibility: visible;
  }
  #section-to-print {
    position: absolute;
    left: 0;
    top: 0;
  }
}
